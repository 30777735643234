import React, {useEffect, useState} from 'react';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import MainContentArea from './components/MainContentArea';
import { useSelector } from 'react-redux';
import { HOME_ROUTE, MODULE_ROUTE } from './routeTypes';
import useQRScanner from '../../library/hooks/deviceIntegration/useQRScannerJavaLoop';

export default function MasterPage(props) {
    const { children } = props;
    const [pathClass, setPathClass] = useState("");
    const serverAttributes = useSelector(state => state.auth.server);

    // console.log("Props", props);
    useQRScanner(true);

    const initialisePath = () => {
        if(props.match.path === HOME_ROUTE){
            setPathClass("uiSarasPathHome");
        }
        else if(props.match.path === MODULE_ROUTE){
            setPathClass("uiSarasPathModule");
        }
        else{
            setPathClass("uiSarasDefaultPath");
        }
    };
    
    useEffect(initialisePath, [props.match]);

    return (
        <div className={`${pathClass} uiSarasPackage${serverAttributes.PackageCode} uiSarasClient${serverAttributes.ClientCode}`}>
            <PageHeader currentPath={props.match.path}/>
            <MainContentArea>
                {children}
            </MainContentArea>
            <PageFooter />
        </div>
    );
}

