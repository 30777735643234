let coreSettings = {
    priorityList: { "Priority": [
        { "value": "1", "label": "Highest Priority (Cannot Delay)", "Short_Name": "A++", "Priority_Order": "1", "Class_Name": "uiP_HighestPriority" },
        { "value": "2", "label": "High Priority", "Short_Name": "A+", "Priority_Order": "2", "Class_Name": "uiP_HighPriority" },
        { "value": "3", "label": "Very Urgent", "Short_Name": "A", "Priority_Order": "3", "Class_Name": "uiP_VeryUrgent" },
        { "value": "4", "label": "Urgent", "Short_Name": "B+", "Priority_Order": "4", "Class_Name": "uiP_Urgent" },
        { "value": "5", "label": "Normal Priority", "Short_Name": "B", "Priority_Order": "5", "Class_Name": "uiP_NormalPriority" },
        { "value": "6", "label": "Low Priority (Can Delay)", "Short_Name": "C", "Priority_Order": "6", "Class_Name": "uiP_LowPriority" },
        { "value": "7", "label": "Priority Not Set", "Short_Name": "F", "Priority_Order": "7", "Class_Name": "uiP_PriorityNotSet" }
        ]
    },

    dateInputFormats: ["DD/MMM/YYYY","DD-MMM-YYYY","DD MMM YYYY","DD/MM/YYYY","DD-MM-YYYY","DD MM YYYY"],

    appDefaultCulture: 'EN',

    defaultUserManualLink: 'https://sarasx.in/000002'

}

export default coreSettings