import { FORM_BUSY, FORM_NOT_BUSY } from "../actions/types";

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {

    if(action.type === FORM_BUSY || action.type === FORM_NOT_BUSY)
    {
        console.log("form action button write", action);

        if(action.payload){
            const formKey = action.payload.formKey;

            const newState = {...state};

            if(action.type === FORM_BUSY) newState[formKey] = 1;
            else delete newState[formKey];
            
            return newState;
        }
    }

    return state;
}
