import db from './db';
import {getFormFieldValue} from "./uiFunctions";

const loginStore = async (response) => {
        const serverObject = getFormFieldValue(response.dataList, "server");

        if(serverObject){
                const jServer = JSON.parse(serverObject);

                // console.log("app version signin", jServer.AppVersion);

                await putGeneralData("app_version", jServer.AppVersion);
                await putGeneralData("user_manual_link", jServer.UserManualLink);
        }
}

const logoutStore = async () => {
        await db.delete();

        return 1;
}

const putGeneralData = async (key, value) => {
        await db.table('gn').put({key: key, value: value});

        return 1;
}

const getGeneralData = async (key) => {
        const result = await db.table('gn').get({key: key});

        if(result) return result.value;

        return null;
}

const clearLocale = async () => {
        await db.table('locale').clear();

        return 1;
}

const putBulkLocale = async (data) => {
        if(data){
                let localeArray = [];

                Object.keys(data).map(function (key) {
                        localeArray.push({key: key, value: data[key]});
                        return null;
                })

                await db.table('locale').bulkPut(localeArray);

                return 1;
        };

        return 1;
}

const getLocaleString = async (key)=>{
        const result = await db.table('locale').get({key: key});

        if(result) return result.value;

        return null;
}

export {loginStore, logoutStore, putGeneralData, getGeneralData, 
        clearLocale, putBulkLocale, getLocaleString};
