import React, {useState, useEffect} from 'react';
import SarasTrans from '../../../library/notifications/SarasTrans'
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'
import coreSettings from '../../../helper/coreSetting';
import {IconHelp} from '../../../library/decor/SarasIcons';

const UserManual=(props)=>{
    const [manualLink, setManualLink] = useState('');
    const localDb = useSarasStore();

    const checkCulture = () => {
        if(!manualLink)
        {
            //get selected culture
            localDb.requestData("user_manual_link", onManualLinkRetrieved);
        }
    };

    useEffect(checkCulture, [manualLink]);

    //this is executed when my culture is retrieved from local db
    const onManualLinkRetrieved = (key, value) =>  {
        let newValue = value;

        //default to EN culture
        if(!newValue) newValue = coreSettings.defaultUserManualLink;

        if((manualLink || '').toLowerCase() !== (newValue || '').toLowerCase()){
            setManualLink(newValue);
        }
    }

    return(
        <>
            <a href={manualLink || coreSettings.defaultUserManualLink} target='#' style={{color:'white',textDecoration:'none'}}>
              <span className='uiAppIconWithLink'>
                <IconHelp className='uiAppIcon'/>
                <span><SarasTrans value="Help" /></span>
              </span>
            </a>
        </>
    )
}

export default UserManual