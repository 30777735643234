import { SIGN_IN, SIGN_OUT, SWITCH_COMPANY, CHECK_SIGN_STATE, RESET_ERRORS, FORM_BUSY, FORM_NOT_BUSY } from "./types"
import SarasErpApi from '../../../../helper/SarasErpApi';
import {reloadWindow} from '../../../../helper/uiFunctions';
import {loginStore, logoutStore} from '../../../../helper/localStorage'
import { SIGN_IN_ROUTE } from '../../../main/routeTypes';
import { useHistory } from "react-router-dom";

export const resetErrors = () => {
    return {
        type: RESET_ERRORS,
        payload: null
    }
}

export const attemptSignIn = (email, pass, remember) => async (dispatch, getState) => {
    const requestObject =
    {
         actionName: 'auth',
         actionId: 'login',
         dataList: {
             'ulid': `${email}`,
             'ulpass': `${pass}`
         }
    }

    const response = await SarasErpApi.post('auth', requestObject);

    const promise = loginStore(response.data);

    promise.then((result) => {
        dispatch({type: SIGN_IN, payload: response.data});
    }).catch(e => {
        // Other error such as a string was thrown
        // console.error (e);
    });


}

export const attemptSwitchCompany = (companyId) => async (dispatch, getState) => {
    
    const requestObject =
    {
         actionName: 'auth',
         actionId: 'switch_company',
         dataList: {
             'companyId': `${companyId}`
         }
    }

    const response = await SarasErpApi.post('core', requestObject);

    //console.log("company action", response.data.isError);
    
    dispatch({type: SWITCH_COMPANY, payload: response.data});

    if(response.data.isError === 0) reloadWindow();
}

export const checkSignInAndAttemptAutoLogin = () => async (dispatch, getState) => {
    //TODO check in local db - don't do now, let server take care of auth

    const requestObject =
    {
        actionName: 'auth',
        actionId: 'isSignedIn',
        dataList: {},
    }

    const response = await SarasErpApi.post('auth', requestObject);

    dispatch({type: CHECK_SIGN_STATE, payload: response.data});
}

export const signOut = () => async (dispatch, getState) => {
    //delete local db
    logoutStore();
    const history = useHistory();

    const requestObject =
    {
         actionName: 'auth',
         actionId: 'logout',
        dataList: {},
    }

    const response = await SarasErpApi.post('auth', requestObject);

    dispatch({type: SIGN_OUT, payload: response.data});
    
    //trigger window reload
    history.replace(SIGN_IN_ROUTE);
    reloadWindow();
}

export const changeFormBusyState = (formKey, isBusy) => async (dispatch, getState) => {
    if(isBusy === 1){
        dispatch({type: FORM_BUSY, payload: {formKey: formKey, isBusy: isBusy}});
    }
    else{
        dispatch({type: FORM_NOT_BUSY, payload: {formKey: formKey, isBusy: isBusy}});
    }
}
