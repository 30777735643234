import {useEffect} from 'react';


//reference for integrating with mobile later: https://github.com/mebjas/html5-qrcode
const useQRScannerJavaLoop = (haveMultipleCapture) => {

    var scannedCode = '';
    var interval;

    const onScanInput = (e) => {
        if (interval)
            clearInterval(interval);

        if (e.code === 'Enter') {

            if (scannedCode)
            {
                // console.log('Scanner output', e);
                sendCallback(scannedCode);
                scannedCode = '';
            }

            return;
        }

        if (e.key !== 'Shift')
            scannedCode += e.key;

        interval = setInterval(() => scannedCode = '', 100);
    }

    let initialiseFormState = function() {

        console.log("scanner initialised");

        document.addEventListener('keydown', onScanInput);

        // component unmount code
        return function () {
            // console.log("withView form uninitialised", actionName, viewId);

            document.removeEventListener('keydown', onScanInput);
        }
    }

    useEffect(initialiseFormState, []);

    const sendCallback = (msg) => {
        console.log("scan event fired");

        let event = new CustomEvent('onScan', {
            detail: {
                haveMultipleCapture: haveMultipleCapture,
                msg: msg
            }
        });

        // dispatch the event
        document.dispatchEvent(event);
    }

    const returnObj = {
        isDevicePaired: true,
        isDeviceConnected: true,
        requestDevicePermission: null
    }

    return returnObj;
}

export default useQRScannerJavaLoop;
